<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="18.626" height="21" viewBox="0 0 18.626 21">
		<g transform="translate(-595 -451)">
			<g transform="translate(-668 -272)">
				<path d="M9,7.241V25.164a.461.461,0,0,0,.691.425L23.9,16.627a.508.508,0,0,0,0-.845L9.691,6.821A.457.457,0,0,0,9,7.241Z" transform="translate(1254 716.88)" fill="#fafafa"/>
				<rect width="4" height="21" rx="2" transform="translate(1277.626 723)" fill="#fafafa"/>
			</g>
		</g>
	</svg>
</template>
