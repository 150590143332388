<template>
	<div class="container margin-bottom-lg live text-center">
	
		<div class="row">
			<div class="col-md-12 col-lg-10 offset-lg-1">
				<div class="margin-bottom-lg">
					<div class="h1 margin-bottom-md">Elite Partner Live</div>
					
					<div class="row">
						<div class="col-md-8 offset-md-2">
							<p class="margin-bottom-md">Elite Partner feiern den Punk-Rock hart und spielen an ihren Konzerten neben Eigenkompositionen auch zahlreiche Klassiker der letzten 40 Jahre: von den Ramones über Bad Religion zu Green Day, von Dead Kennedys bis <nobr>Blink 182</nobr> oder The Offspring.</p>
						</div>
					</div>
					
					<iframe style="width: 100%; max-width: 560px;" height="315" src="https://www.youtube.com/embed/X9EJTKqZFyE?si=ixwreFn0r-IhxA-I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
					
				</div>
			</div>
		</div>	
		
		<div class="row margin-bottom-lg">
			<div class="col-md-12 col-lg-10 offset-lg-1">
				<div class="margin-bottom-lg">
					<div class="h2 margin-bottom">Konzerte</div>
					<div class="h3">2024</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>MI | 17. Januar 2024<br/>LAAX Open</h5>
							<p class="no-margin">Konzert ab 20:00, Talstation LAAX rocksresort Stage</p>
							<p><a href="https://open.laax.com/" target="_blank">https://open.laax.com/</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>FR | 26. Januar 2024<br/>Story Thusis</h5>
							<p class="no-margin">Konzert ab 21:00</p>
							<p><a href="https://story-thusis.ch/" target="_blank">story-thusis.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 9. März 2024<br/>Funkensamstag, Widnau</h5>
							<p class="no-margin">Konzert ab 21:00</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 6. April 2024<br/>Photobastei Zürich</h5>
							<p class="no-margin">Privatanlass</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 11. Mai 2024<br/>Henry's Bar, Baden</h5>
							<p class="no-margin">Konzert ab 21:00</p>
							<p><a href="https://www.henrysbar.ch/" target="_blank">henrysbar.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 1. Juni 2024<br/>BonAir, Bonaduz</h5>
							<p class="no-margin">Konzert ab 23:00</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 20. Juli 2024<br/>Openair Safiental</h5>
							<p class="no-margin">Konzert ab 21:30</p>
							<p><a href="https://openair-safiental.ch/" target="_blank">openair-safiental.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>DO | 26. September 2024<br/>Erstsemestrigenfest ETH Zürich</h5>
							<p class="no-margin">Konzert ab 23:30</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 26. Oktober 2024<br/>Postremise Chur</h5>
							<p class="no-margin">30 Jahre Toms Beer Box & Phil's Concert & Showtechnique</p>
							<p class="no-margin">Türöffnung 19:00, Konzerte ab 20:30</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 9. November 2024<br/>Riders Club Laax</h5>
							<p class="no-margin">Schetg Gru Mini Madness</p>
							<p class="no-margin">Skate Contest ab 14:00, Konzert ab 23:00</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row margin-bottom-lg">
			<div class="col-md-12 col-lg-10 offset-lg-1">
				<div class="margin-bottom-lg">
					<div class="h3">2023</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>DO | 19. Januar 2023<br/>Kurhaus Lenzerheide</h5>
							<p class="no-margin">Chopfab Party</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 11. Februar 2023<br/>Bierhalle, Balgach</h5>
							<p class="no-margin">Konzert ab 21:30</p>
							<p><a href="https://www.bierhalle-balgach.ch/" target="_blank">bierhalle-balgach.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>MI | 15. März 2023<br/>Centre Fries, Fribourg</h5>
							<p class="no-margin">Konzert ab 21:00</p>
							<p><a href="https://student.unifr.ch/centrefries/" target="_blank">student.unifr.ch/centrefries</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 18. März 2023<br/>Henry's Bar, Baden</h5>
							<p class="no-margin">Konzert ab 21:00</p>
							<p><a href="https://www.henrysbar.ch/" target="_blank">henrysbar.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>DO | 30. März 2023<br/>Crest'Ota, Lenzerheide</h5>
							<p class="no-margin">Warm Up Live is Life Festival, Konzert ab 17:00</p>
							<p><a href="https://crestota.ch/" target="_blank">crestota.ch</a> | <a href="https://www.liveislife.ch/" target="_blank">liveislife.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 15. April 2023<br/>Sportz und Beatz, Thusis</h5>
							<p class="no-margin">Konzert ab 12:30</p>
							<p><a href="https://www.sportzundbeatz.ch/" target="_blank">sportzundbeatz.ch/</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>FR | 16. Juni 2023<br/>Polenta7000, Chur</h5>
							<p class="no-margin">17:00, Konzert ab 20:15</p>
							<p><a href="https://www.polenta7000.ch/" target="_blank">polenta7000.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 5. August 2023<br/>Chapella Open Air</h5>
							<p class="no-margin">Konzert ab 00:00</p>
							<p><a href="https://chapella.ch/" target="_blank">chapella.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 18. November 2023<br/>Bierhalle, Balgach</h5>
							<p class="no-margin">Konzert ab 21:30</p>
							<p><a href="https://www.bierhalle-balgach.ch/" target="_blank">bierhalle-balgach.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 16. Dezember 2023<br/>Toms Beer Box, Chur</h5>
							<p><a href="https://www.facebook.com/toms.beer.box/" target="_blank">toms.beer.box</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row">
			<div class="col-md-12 col-lg-10 offset-lg-1">
				<div class="margin-bottom-lg">
					<div class="h3">2022</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>FR | 25. März 2022<br/>Klimademo Chur</h5>
							<p class="no-margin">Bahnhofstrasse Chur</p>
							<p><a href="https://www.klimastreik-graubuenden.ch" target="_blank">klimastreik-graubuenden.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 2. April 2022<br/>Cinema Sil Plaz, Ilanz</h5>
							<p class="no-margin">Mit Knöppel (ehr Wichser!)</p>
							<p><a href="http://knoeppel.ch/" target="_blank">knoeppel.ch</a> | <a href="https://cinemasilplaz.ch" target="_blank">cinemasilplaz.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>FR | 27. Mai 2022<br/>MisoXperience, Misox Tal</h5>
							<p class="no-margin">Outdoorfestival | Ab 20:00</p>
							<p><a href="http://misoxperience.ch/" target="_blank">misoxperience.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>SA | 4. Juni 2022<br/>Fiona & Robert</h5>
							<p>Hochzeit in Wetzikon<br />Nur für geladene Gäste und Wedding Crashers</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>FR | 1. Juli 2022<br/>Polenta7000, Chur</h5>
							<p class="no-margin">18:00, Konzert ab 21:00</p>
							<p><a href="https://www.polenta7000.ch/" target="_blank">polenta7000.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>Do | 21. Juli 2022<br/>Ebrietas, Zürich</h5>
							<p class="no-margin">Mit Topsy Turvy's (FR)</p>
							<p><a href="https://ebrietas.ch/" target="_blank">ebrietas.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>Fr | 11. November 2022<br/>Noerd, Zürich</h5>
							<p class="no-margin">Benefizessen AL Zürich</p>
							<p><a href="https://al-zh.ch/blog/2022/10/benefizessen/" target="_blank">al-zh.ch</a></p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="margin-bottom-md">
							<h5>Sa | 26. November 2022<br/>Mundwerk, Zürich</h5>
							<p class="no-margin">Mit Fake North und Zirka</p>
							<p><a href="https://mw-club.ch/event/punk-rock-retreat-2/" target="_blank">Punkrock Retreat</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			img: null,
		}
	}
}
</script>
