<template>
	<div class="container margin-bottom-xl footer text-center">
		<p class="margin-bottom-md">
			<a href="https://elitepartner1.bandcamp.com/" title="Bandcamp" target="_blank"><i class="fa fa-2x fa-bandcamp"></i></a>&nbsp;
			<a href="mailto:band@elitepartner.band" title="E-Mail" target="_blank"><i class="fa fa-2x fa-at"></i></a>&nbsp;
			<!--- <a href="https://open.spotify.com/artist/12Qed4J8BfPDQ3RQpdnsCB" title="Spotify" target="_blank"><i class="fa fa-2x fa-spotify"></i></a>&nbsp; --->
			<a href="https://www.instagram.com/elitepartner.band/" title="Instagram" target="_blank"><i class="fa fa-2x fa-instagram"></i></a>&nbsp;
			
		</p>
		<p class="text-muted">&copy; EP Entertainment</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			img: null,
		}
	}
}
</script>
