<template>
	<div id="top-carousel" class="jumbotron">
		<div class="carousel-inner">
			<div class="carousel-item active">
				<div v-bind:class="bgPosition" class="img-bg fade-in" style="background-image: url('img/header.jpg');"></div>
			</div>
			<div class="carousel-caption">
				<div class="container">
					<div class="row">
						<div class="col-md-7 col-lg-6">
							<div class="e-series-container">
								<div class="e-series">
									<div class="e">E</div>
									<div class="series">SERIE</div>
								</div>
							</div>
							<img class="logo margin-bottom" src="/img/logo.svg">
							<p>
								<strong>12 Songs</strong>
								<span class="box">16+</span>
								<span class="stars">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star-o"></i>
									<i class="fa fa-star-o"></i>
									<i class="fa fa-star-o"></i>
								</span>
							</p>
							<p class="lead margin-bottom-md">Eine Möchtegernpunkband auf ihren Abenteuern: Mitch, Kirk, Roy und Tyler halten an ihrem Teenietraum fest.</p>
							
							<div class="cta">
								<a class="btn btn-primary play-text-btn" v-on:click.prevent="play()" href="#episodes">
									<PlayIcon/>
									Abspielen
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PlayIcon from '@/components/icons/player/Play.vue'

export default {
	components: {
		PlayIcon
	},
	data() {
		return {
			bgPosition: 'left',
			currentPosition: 1,
			bgPositions: {
				1: 'left',
				2: 'right',
				3: 'center'
			}
		}
	},
	methods: {
		bgPositionInterval: function () {
			var next = this.currentPosition+1;
			if (next > 3) {
				next = 1
			}
			this.currentPosition = next
			this.bgPosition = this.bgPositions[next]
		},
		play: function () {
			document.getElementById('episodes').scrollIntoView();
			var player = this.$parent.$refs.player
			player.play()
		}
	},
	mounted() {
		setInterval(this.bgPositionInterval, 10000);
	}
}
</script>
