<template>
	<div id="app">
		<Header ref="header"/>
		<Episodes ref="episodes"/>
		<Live ref="live"/>
		<Footer ref="footer"/>
		<Player ref="player"/>
	</div>
</template>

<script>
import Header from './components/Header.vue'
import Episodes from './components/Episodes.vue'
import Live from './components/Live.vue'
import Footer from './components/Footer.vue'
import Player from './components/Player.vue'

export default {
  name: 'App',
  components: {
    Header,
    Episodes,
    Live,
    Footer,
    Player
  }
}
</script>
