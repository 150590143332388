import { createApp } from 'vue'
import App from './App.vue'
import './scss/custom.scss'
//import i18n from './i18n'
import VueGtag from "vue-gtag";

//createApp(App).use(i18n).mount('#app')
createApp(App).use(VueGtag, {
	config: { id: "G-SENJJ21MR9" }
}).mount("#app");
