<template>
	<div id="episodes" class="container margin-bottom-xxl">
		<div class="row">
			<div class="col-md-11 col-lg-9 col-xl-8">
				<div class="margin-bottom-md">
					<h3 class="margin-bottom-sm">Folgen</h3>
					<strong>Staffel 1:</strong> 12 Songs
					<hr />
				</div>
				<div class="margin-bottom-md" :key="episode" v-for="(episode, i) in episodes">
					
					<div v-if="!episode.comingSoon" class="row margin-bottom-md">
						<div class="col-5">
							<div class="img-container preview-img" title="Abspielen" v-on:click.prevent="togglePlay(i)">
								<img class="img-fluid" v-bind:src="episode.img" />
								<a class="play-btn" title="Abspielen" href="#">
									<PauseIcon v-if="isPlaying(i)"/>
									<PlayIcon v-else/>
								</a>
							</div>
						</div>
						<div class="col-7">
							<h4>{{ episode.title }}</h4>
							<p>{{ episode.description }}</p>
							<div class="small text-muted no-margin"><i class="far fa-clock"></i> {{ episode.time }}</div>
						</div>
					</div>
					
					<div v-else class="row margin-bottom-md inactive-item">
						<div class="col-5">
							<div class="img-container preview-img">
								<img class="img-fluid" v-bind:src="episode.img" />
							</div>
						</div>
						<div class="col-7">
							<h4>{{ episode.title }}</h4>
							<p>{{ episode.description }}</p>
							<div class="small text-alert no-margin">COMING SOON!</div>
						</div>
					</div>
					
				</div>
				
				<div class="inactive-item">
					<div class="col-12 col-md-5 text-center">
						<p>Fortsetzung folgt ...</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import PlayIcon from '@/components/icons/player/PlayO.vue'
import PauseIcon from '@/components/icons/player/PauseO.vue'

export default {
	components: {
		PlayIcon,
		PauseIcon
	},
	data () {
		return {
			episodes: null
		}
	},
	methods: {
		fetchEpisodes: function () {
			let str = (Math.random() + 1).toString(36).substring(7)
			axios.get('data/episodes.json?'+ str).then((result) => {
				this.episodes = result.data.episodes
			})
		},
		togglePlay: function (i) {
			var player = this.$parent.$refs.player
			
			if (this.isPlaying(i)) {
				player.pause();
			}
			else {
				if (player.nowPlaying == i)
				{
					player.play();
				}
				else {
					player.directPlay(i)
				}
			}
		},
		isPlaying: function(i) {
			var player = this.$parent.$refs.player
			if (player.playing && player.nowPlaying == i) {
				return true;
			}
			else {
				return false;
			}
		}
	},
	mounted() {
		this.fetchEpisodes()
	}
}
</script>
