<template>
	<div ref="player" id="player" class="fadein">
		<audio ref="audio" id="audio" preload="auto"></audio>
		<div class="player-container">
			<div class="player-img-wrapper">
				<img ref="playerImg" class="player-img" src="" />
			</div>
			<div class="player-info">
				<div ref="playerTitle" class="info"></div>
				<div class="info label">Elite Partner</div>
			</div>
			<div class="player-controls" v-on:click="prevent">
				<!---
				<a class="player-prev" title="Zurück" v-on:click.prevent="prev()" href="#">
					<PrevIcon/>
				</a>
				--->
				
				<a class="play-btn" v-on:click.prevent="playPause" v-bind:title="playBtnTitle()" href="#">
					<PlayIcon v-if="!playing"/>
					<PauseIcon v-else/>
				</a>
				
				<a class="player-next" title="Weiter" v-on:click.prevent="next()" href="#">
					<NextIcon/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import PlayIcon from '@/components/icons/player/Play.vue'
import PauseIcon from '@/components/icons/player/Pause.vue'
//import PrevIcon from '@/components/icons/player/Prev.vue'
import NextIcon from '@/components/icons/player/Next.vue'

export default {
	components: {
		PlayIcon,
		PauseIcon,
		//PrevIcon,
		NextIcon
	},
	data: () => ({
		episodes: null,
		autoPlay: false,
		audio: undefined,
		playerMarkup: undefined,
		expanded: false,
		currentSeconds: 0,
		durationSeconds: 0,
		sentGaSeconds: 0,
		loaded: false,
		playing: false,
		hideSeek: true,
		previousVolume: 100,
		showVolume: false,
		volume: 100,
		nowPlaying: 0
	}),
	computed: {
		muted() {
			return this.volume / 100 === 0;
		},
		percentComplete() {
			return parseInt(this.currentSeconds / this.durationSeconds * 100);
		}
	},
	watch: {
		volume() {
			this.audio.volume = this.volume / 100;
		}
	},
	methods: {
		load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true
				this.durationSeconds = parseInt(this.audio.duration)
				this.audio.currentTime = this.currentSeconds
			}
			else {
				throw new Error('Failed to load sound file.')
			}
		},
		playPause() {
			if (this.playing) {
				this.pause()
			}
			else {
				this.play()
			}
		},
		play() {
			this.playing = true
			this.audio.play()
			this.gaEvent('play', true)
		},
		pause() {
			this.playing = false
			this.audio.pause()
			this.gaEvent('pause', true)
		},
		mute() {
			if (this.muted) {
				return this.volume = this.previousVolume
			}
			this.previousVolume = this.volume
			this.volume = 0
		},
		seek(e) {
			e.stopPropagation();
			if (!this.loaded) return;
			var seekPos = e.target.value/100 // percentage
			this.audio.currentTime = parseInt(this.audio.duration * seekPos)
			this.gaEvent('seek', true)
		},
		stop() {
			this.playing = false
			this.audio.setAttribute("src", '')
			this.audio.currentTime = 0
		},
		update() {
			this.currentSeconds = parseInt(this.audio.currentTime);
			
			if (this.currentSeconds%60 == 0) {
				this.sendGaProgress(this.currentSeconds)
			}
		},
		fetchEpisodes: function () {
			let str = (Math.random() + 1).toString(36).substring(7)
			axios.get('data/episodes.json?'+ str).then((result) => {
				this.episodes = result.data.episodes
				this.setMedia(0)
			})
		},
		setMedia(i) {
			var episode = this.episodes[i]
			if (!episode.comingSoon)
			{
				this.nowPlaying = i
				this.audio.setAttribute("src", episode.audio +"?"+ Date.now())
				this.playerImg.setAttribute("src", episode.img)
				this.playerTitle.innerHTML = episode.title
				this.currentSeconds = 0
			
				if (this.autoPlay) {
					this.play()
				}
			}
		},
		next() {
			this.stop();
			this.autoPlay = true
			var next = this.nowPlaying+1
			if (this.episodes.length > next) {
				if (this.episodes[next].comingSoon) {
					this.setMedia(0)
				}
				else {
					this.setMedia(next)
				}
			}
			else {
				this.setMedia(0)
			}
		},
		prev() {
			this.stop();
			this.autoPlay = true
			var prev = this.nowPlaying-1
			if (prev >= 0) {
				this.setMedia(prev)
			}
			else {
				var last = this.episodes.length-1
				this.setMedia(last)
			}
		},
		directPlay(i) {
			this.stop();
			this.autoPlay = true
			this.setMedia(i)
		},
		complete() {
			this.next()
			this.gaEvent('complete', true)
		},
		expand() {
			this.expanded = true;
			this.playerMarkup.classList.add("full")
			// this.gaEvent('expand', false)
		},
		close(e) {
			this.prevent(e)
			this.expanded = false;
			this.playerMarkup.classList.remove("full")
			// this.gaEvent('collapse', false)
		},
		prevent(e) {
			e.stopPropagation();
		},
		sendGaProgress(seconds) {
			if (this.sentGaSeconds != seconds) {
				this.sentGaSeconds = seconds
				this.gaEvent('progress', true)
			}
		},
		gaEvent(action, includeLabel) {
			var params = {'event_category': 'player'}
			if (includeLabel) {
				var label = this.nowPlaying.item.title
				params = {'event_category': 'player', 'event_label': label}
			}
			this.$gtag.event(action, params)
		},
		playBtnTitle() {
			if (this.playing)
			{
				return 'Pause'
			}
			else {
				return 'Abspielen'
			}
		}
	},
	mounted() {
		this.playerMarkup = this.$refs['player']
		this.audio = this.$refs['audio']
		this.playerImg = this.$refs['playerImg']
		this.playerTitle = this.$refs['playerTitle']
		
		this.audio.addEventListener('timeupdate', this.update);
		this.audio.addEventListener('loadeddata', this.load);
		this.audio.addEventListener('pause', () => { this.playing = false; });
		this.audio.addEventListener('play', () => { this.playing = true; });
		this.audio.addEventListener('ended', () => { this.complete() });
		
		this.fetchEpisodes()
	}
}

</script>